export const GET_VOUCHER_LIST = "GET_VOUCHER_LIST"
export const GET_VOUCHER_LIST_SUCCESS = "GET_VOUCHER_LIST_SUCCESS"

export const SAVE_VOUCHER_MASTER = "SAVE_VOUCHER_MASTER"
export const SAVE_VOUCHER_SUCCESS = "SAVE_VOUCHER_SUCCESS"

export const EDIT_VOUCHER_ID = "EDIT_VOUCHER_ID"
export const EDIT_VOUCHER_ID_SUCCESS = "EDIT_VOUCHER_ID_SUCCESS"

export const UPDATE_VOUCHER_ID = "UPDATE_VOUCHER_ID"
export const UPDATE_VOUCHER_ID_SUCCESS = "UPDATE_VOUCHER_ID_SUCCESS"

export const DELETE_VOUCHER_ID = "DELETE_VOUCHER_ID"
export const DELETE_VOUCHER_ID_SUCCESS = "DELETE_VOUCHER_ID_SUCCESS"

export const VALIDE_VOUCHER_ID = "VALIDE_VOUCHER_ID"
export const VALIDE_VOUCHER_ID_SUCCESS = "VALIDE_VOUCHER_ID_SUCCESS"

export const VOUCHER_ERROR_ACTION = "VOUCHER_ERROR_ACTION"




