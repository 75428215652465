export const DATA_EXPORT_TO_SAP_ACTION = 'DATA_EXPORT_TO_SAP_ACTION'
export const DATA_EXPORT_TO_SAP_ACTION_SUCCESS = 'DATA_EXPORT_TO_SAP_ACTION_SUCCESS'
export const DATA_EXPORT_TO_SAP_ERROR_ACTION = 'DATA_EXPORT_TO_SAP_ERROR_ACTION'



export const FETCH_UPLOADED_FILE_ACTION = 'FETCH_UPLOADED_FILE_ACTION'
export const FETCH_UPLOADED_FILE_ACTION_SUCCESS = 'FETCH_UPLOADED_FILE_ACTION_SUCCESS'
export const FETCH_UPLOADED_FILE_ERROR_ACTION = 'FETCH_UPLOADED_FILE_ERROR_ACTION'

